const headline = {
  '2xl': {
    font: 'primary',
    size: '66px',
    weight: '700',
    leading: '1.2',
  },
  xl: {
    font: 'primary',
    size: '41px',
    weight: '700',
    leading: '1.2',
  },
  lg: {
    font: 'primary',
    size: '23px',
    weight: '700',
    leading: '1.2',
  },
  md: {
    font: 'primary',
    size: '18px',
    weight: '700',
    leading: '1.2',
  },
  sm: {
    font: 'primary',
    size: '16px',
    weight: '700',
    leading: '1.2',
  },
  xs: {
    font: 'primary',
    size: '14px',
    weight: '700',
    leading: '1.2',
  },
  '2xs': {
    font: 'primary',
    size: '12px',
    weight: '700',
    leading: '1.2',
  },
};

const preamble = {
  lg: {
    font: 'primary',
    size: '23px',
    leading: '1.5',
    weight: '700',
  },
  md: {
    font: 'primary',
    size: '18px',
    leading: '1.5',
    weight: '700',
  },
  sm: {
    font: 'primary',
    size: '16px',
    leading: '1.5',
    weight: '700',
  },
};

const body = {
  lg: {
    font: 'secondary',
    size: '23px',
    leading: '1.5',
    weight: '400',
  },
  md: {
    font: 'secondary',
    size: '18px',
    leading: '1.5',
    weight: '400',
  },
  sm: {
    font: 'secondary',
    size: '16px',
    leading: '1.5',
    weight: '400',
  },
  xs: {
    font: 'secondary',
    size: '14px',
    leading: '1.2',
    weight: '400',
  },
};

const quote = {
  md: {
    font: 'primary',
    size: '23px',
    weight: '700',
    style: 'oblique',
    leading: '1.25',
  },
  sm: {
    font: 'primary',
    size: '18px',
    weight: '700',
    style: 'oblique',
    leading: '1.5',
  },
};

const input = {
  lg: {
    font: 'secondary',
    size: '18px',
    leading: '1.2',
    weight: '400',
  },
  md: {
    font: 'secondary',
    size: '16px',
    leading: '1.2',
    weight: '400',
  },
  sm: {
    font: 'secondary',
    size: '14px',
    leading: '1.2',
    weight: '400',
  },
  xs: {
    font: 'secondary',
    size: '11px',
    leading: '1.2',
    weight: '400',
  },
};

const typography = {
  headline: {
    DEFAULT: headline.md,
    ...headline,
  },
  preamble: {
    DEFAULT: preamble.md,
    ...preamble,
  },
  body: {
    DEFAULT: body.md,
    ...body,
  },
  quote: {
    DEFAULT: quote.md,
    ...quote,
  },
  input: {
    DEFAULT: input.md,
    ...input,
  },
};

module.exports = typography;
