import { tw } from '@/utils/tw';
import BaseImage from 'base/components/standalone/Image/theme';

const Image = tw.theme({
  extend: BaseImage,
  slots: {
    captionGroup: 'text-body-xs sm:text-body-sm',
  },
});

export default Image;
