const typographyPlugin = require('tailwind-utils/typography.plugin');
const fontSizeVariantsPlugin = require('tailwind-utils/fontSizeVariants.plugin');

const colorsConfig = require('./configs/tailwind.colors');
const typographyConfig = require('./configs/tailwind.typography');
const proseConfig = require('./configs/tailwind.prose');

const fontFamily = {
  primary: `var(--font-primary), Arial, sans-serif`,
  secondary: `Arial, sans-serif`,
};

/** @type {import('tailwindcss').Config} */

module.exports = {
  theme: {
    extend: {
      fontFamily,
      colors: colorsConfig,

      typography: proseConfig(typographyConfig),

      maxWidth: {
        tabloid: '980px',
      },
    },
  },

  plugins: [typographyPlugin(typographyConfig), fontSizeVariantsPlugin],
};
