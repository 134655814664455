const primary = {
  50: '#FCFCFC',
  100: '#FFECF1',
  200: '#FFD9E6',
  300: '#FFAFCE',
  400: '#FF83B9',
  500: '#EC5D9E',
  600: '#CC4384',
  700: '#AB286A',
  800: '#8C0552',
  900: '#640037',
};

const secondary = {
  50: '#FDFBFF',
  100: '#ECF0FF',
  200: '#D7E2FF',
  300: '#ADC6FF',
  400: '#81AAFF',
  500: '#538DFC',
  600: '#2E6FE0',
  700: '#0059C6',
  800: '#00429B',
  900: '#002D6F',
};

const gray = {
  50: '#FEFBFF',
  100: '#F2F0F4',
  200: '#E3E2E6',
  300: '#C7C6CA',
  400: '#ACABAF',
  500: '#919094',
  600: '#77777A',
  700: '#5E5E62',
  800: '#46464A',
  900: '#1B1B1F',
};

const red = {
  50: '#FFF0F0',
  100: '#FFDDDE',
  200: '#FFC1C3',
  300: '#FF979A',
  400: '#FF5B60',
  500: '#FF282E',
  600: '#EE050C',
  700: '#D30208',
  800: '#AE060B',
  900: '#8F0D11',
};

const yellow = {
  50: '#FFFAEB',
  100: '#FEF1C7',
  200: '#FEE389',
  300: '#FDD35D',
  400: '#FCB923',
  500: '#F6970A',
  600: '#DA7105',
  700: '#B54E08',
  800: '#933C0D',
  900: '#78320F',
};

const blue = {
  100: '#D3F2FB',
  700: '#146681',
};

const colors = {
  primary,
  secondary,
  gray,
  red,
  yellow,
  blue,
};

module.exports = colors;
