/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { HantStandaloneImage as ResolvedStandaloneImage } from 'sites/hant/components/standalone/Image';

export const Image: typeof ResolvedStandaloneImage = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneImage {...props} />
        </ErrorBoundary>
    );
});

export type ImageProps = PropsFromComponent<typeof Image>;

/** @deprecated Use slot architecture instead */
export const ImageWith = (extras: DynamicStandaloneExtras): typeof Image => {
    return function Image(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Image');
        return <ResolvedStandaloneImage {...mergeProps({ options: { theme } }, props)} />;
    }
}