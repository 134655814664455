let plugin = require('tailwindcss/plugin');

const fontSizes = Array.from({ length: 350 }, (_, index) => index + 1);

const desktopFontUtilities = Object.fromEntries(
  fontSizes.map((value) => [
    `.t${value}`,
    {
      '@media (min-width: 980px)': {
        fontSize: value,
      },
    },
  ])
);

const SUITABLE_MULTIPLIER_FACTOR_FOR_VIEW_WIDTH = 0.24;

const mobileFontUtilities = Object.fromEntries(
  fontSizes.map((value) => [
    `.tm${value}`,
    {
      '@media (max-width: 980px)': {
        fontSize: `calc((100vw / 100) * ${SUITABLE_MULTIPLIER_FACTOR_FOR_VIEW_WIDTH} * ${value})!important`,
      },
    },
  ])
);

module.exports = plugin(function ({ addUtilities }) {
  addUtilities({ ...desktopFontUtilities, ...mobileFontUtilities });
});
