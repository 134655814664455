const plugin = require('tailwindcss/plugin');
const exists = Boolean;

const getKey = (...fragments) => fragments.filter(exists).join('-');

const getThemeValue = (theme) => (prop, value) => {
  const themeValue = theme(`${prop}.${value}`);

  return themeValue ?? value;
};

const getVariant = (theme, { font, size, weight, style, tracking, leading, transform, align, color } = {}) => {
  const getValue = getThemeValue(theme);

  return {
    fontFamily: getValue('fontFamily', font),
    fontSize: getValue('fontSize', size),
    fontWeight: getValue('fontWeight', weight),
    fontStyle: getValue('fontStyle', style),
    letterSpacing: getValue('letterSpacing', tracking),
    lineHeight: getValue('lineHeight', leading),
    textTransform: getValue('textTransform', transform),
    textAlign: getValue('textAlign', align),
    color: getValue('colors', color),
  };
};

const getVariantsGroup = (theme, group, variants) => {
  return Object.entries(variants).reduce((result, [variant, values]) => {
    const modifier = variant === 'DEFAULT' ? null : variant;
    const key = getKey('.text', group, modifier);
    return { ...result, [key]: getVariant(theme, values) };
  }, {});
};

const getUtilities = (theme, config, { screen } = {}) => {
  return Object.entries(config).reduce((result, [group, variants]) => {
    const key = getKey(group, screen);
    return { ...result, ...getVariantsGroup(theme, key, variants) };
  }, {});
};

const typographyPlugin = (config, { screen } = {}) => {
  return plugin(({ addUtilities, theme }) => {
    const typographyStyles = getUtilities(theme, config, { screen });
    return addUtilities(typographyStyles);
  });
};

module.exports = Object.assign(typographyPlugin, { getUtilities });
